/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxIdDomainSettingsAdvertisementGtagForDomainOptionsEnv } from './FxIdDomainSettingsAdvertisementGtagForDomainOptionsEnv';
import {
    FxIdDomainSettingsAdvertisementGtagForDomainOptionsEnvFromJSON,
    FxIdDomainSettingsAdvertisementGtagForDomainOptionsEnvFromJSONTyped,
    FxIdDomainSettingsAdvertisementGtagForDomainOptionsEnvToJSON,
    FxIdDomainSettingsAdvertisementGtagForDomainOptionsEnvToJSONTyped,
} from './FxIdDomainSettingsAdvertisementGtagForDomainOptionsEnv';

/**
 * 
 * @export
 * @interface FxIdDomainSettingsAdvertisementGtagForDomainOptions
 */
export interface FxIdDomainSettingsAdvertisementGtagForDomainOptions {
    /**
     * Google tag
     * @type {string}
     * @memberof FxIdDomainSettingsAdvertisementGtagForDomainOptions
     */
    Tag: string;
    /**
     * 
     * @type {FxIdDomainSettingsAdvertisementGtagForDomainOptionsEnv}
     * @memberof FxIdDomainSettingsAdvertisementGtagForDomainOptions
     */
    Env?: FxIdDomainSettingsAdvertisementGtagForDomainOptionsEnv | null;
}

/**
 * Check if a given object implements the FxIdDomainSettingsAdvertisementGtagForDomainOptions interface.
 */
export function instanceOfFxIdDomainSettingsAdvertisementGtagForDomainOptions(value: object): value is FxIdDomainSettingsAdvertisementGtagForDomainOptions {
    if (!('Tag' in value) || value['Tag'] === undefined) return false;
    return true;
}

export function FxIdDomainSettingsAdvertisementGtagForDomainOptionsFromJSON(json: any): FxIdDomainSettingsAdvertisementGtagForDomainOptions {
    return FxIdDomainSettingsAdvertisementGtagForDomainOptionsFromJSONTyped(json, false);
}

export function FxIdDomainSettingsAdvertisementGtagForDomainOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsAdvertisementGtagForDomainOptions {
    if (json == null) {
        return json;
    }
    return {
        
        'Tag': json['Tag'],
        'Env': json['Env'] == null ? undefined : FxIdDomainSettingsAdvertisementGtagForDomainOptionsEnvFromJSON(json['Env']),
    };
}

export function FxIdDomainSettingsAdvertisementGtagForDomainOptionsToJSON(json: any): FxIdDomainSettingsAdvertisementGtagForDomainOptions {
    return FxIdDomainSettingsAdvertisementGtagForDomainOptionsToJSONTyped(json, false);
}

export function FxIdDomainSettingsAdvertisementGtagForDomainOptionsToJSONTyped(value?: FxIdDomainSettingsAdvertisementGtagForDomainOptions | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Tag': value['Tag'],
        'Env': FxIdDomainSettingsAdvertisementGtagForDomainOptionsEnvToJSON(value['Env']),
    };
}

