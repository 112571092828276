/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxIdDomainSettingsAdvertisementGtagOptionsDefault } from './FxIdDomainSettingsAdvertisementGtagOptionsDefault';
import {
    FxIdDomainSettingsAdvertisementGtagOptionsDefaultFromJSON,
    FxIdDomainSettingsAdvertisementGtagOptionsDefaultFromJSONTyped,
    FxIdDomainSettingsAdvertisementGtagOptionsDefaultToJSON,
    FxIdDomainSettingsAdvertisementGtagOptionsDefaultToJSONTyped,
} from './FxIdDomainSettingsAdvertisementGtagOptionsDefault';
import type { FxIdDomainSettingsAdvertisementGtagForDomainOptions } from './FxIdDomainSettingsAdvertisementGtagForDomainOptions';
import {
    FxIdDomainSettingsAdvertisementGtagForDomainOptionsFromJSON,
    FxIdDomainSettingsAdvertisementGtagForDomainOptionsFromJSONTyped,
    FxIdDomainSettingsAdvertisementGtagForDomainOptionsToJSON,
    FxIdDomainSettingsAdvertisementGtagForDomainOptionsToJSONTyped,
} from './FxIdDomainSettingsAdvertisementGtagForDomainOptions';

/**
 * 
 * @export
 * @interface FxIdDomainSettingsAdvertisementGtagOptions
 */
export interface FxIdDomainSettingsAdvertisementGtagOptions {
    /**
     * 
     * @type {FxIdDomainSettingsAdvertisementGtagOptionsDefault}
     * @memberof FxIdDomainSettingsAdvertisementGtagOptions
     */
    Default?: FxIdDomainSettingsAdvertisementGtagOptionsDefault | null;
    /**
     * Настройки для конвретных доменов. Может быть пустым.
     * @type {{ [key: string]: FxIdDomainSettingsAdvertisementGtagForDomainOptions; }}
     * @memberof FxIdDomainSettingsAdvertisementGtagOptions
     */
    Domains?: { [key: string]: FxIdDomainSettingsAdvertisementGtagForDomainOptions; } | null;
}

/**
 * Check if a given object implements the FxIdDomainSettingsAdvertisementGtagOptions interface.
 */
export function instanceOfFxIdDomainSettingsAdvertisementGtagOptions(value: object): value is FxIdDomainSettingsAdvertisementGtagOptions {
    return true;
}

export function FxIdDomainSettingsAdvertisementGtagOptionsFromJSON(json: any): FxIdDomainSettingsAdvertisementGtagOptions {
    return FxIdDomainSettingsAdvertisementGtagOptionsFromJSONTyped(json, false);
}

export function FxIdDomainSettingsAdvertisementGtagOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsAdvertisementGtagOptions {
    if (json == null) {
        return json;
    }
    return {
        
        'Default': json['Default'] == null ? undefined : FxIdDomainSettingsAdvertisementGtagOptionsDefaultFromJSON(json['Default']),
        'Domains': json['Domains'] == null ? undefined : (mapValues(json['Domains'], FxIdDomainSettingsAdvertisementGtagForDomainOptionsFromJSON)),
    };
}

export function FxIdDomainSettingsAdvertisementGtagOptionsToJSON(json: any): FxIdDomainSettingsAdvertisementGtagOptions {
    return FxIdDomainSettingsAdvertisementGtagOptionsToJSONTyped(json, false);
}

export function FxIdDomainSettingsAdvertisementGtagOptionsToJSONTyped(value?: FxIdDomainSettingsAdvertisementGtagOptions | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Default': FxIdDomainSettingsAdvertisementGtagOptionsDefaultToJSON(value['Default']),
        'Domains': value['Domains'] == null ? undefined : (mapValues(value['Domains'], FxIdDomainSettingsAdvertisementGtagForDomainOptionsToJSON)),
    };
}

