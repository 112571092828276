/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdDomainSettingsAdvertisementGtagOptions } from './FxIdDomainSettingsAdvertisementGtagOptions';
import {
    instanceOfFxIdDomainSettingsAdvertisementGtagOptions,
    FxIdDomainSettingsAdvertisementGtagOptionsFromJSON,
    FxIdDomainSettingsAdvertisementGtagOptionsFromJSONTyped,
    FxIdDomainSettingsAdvertisementGtagOptionsToJSON,
} from './FxIdDomainSettingsAdvertisementGtagOptions';

/**
 * @type FxIdDomainSettingsAdvertisementOptionsGtag
 * Настройки для Google Tag
 * @export
 */
export type FxIdDomainSettingsAdvertisementOptionsGtag = FxIdDomainSettingsAdvertisementGtagOptions;

export function FxIdDomainSettingsAdvertisementOptionsGtagFromJSON(json: any): FxIdDomainSettingsAdvertisementOptionsGtag {
    return FxIdDomainSettingsAdvertisementOptionsGtagFromJSONTyped(json, false);
}

export function FxIdDomainSettingsAdvertisementOptionsGtagFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsAdvertisementOptionsGtag {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdDomainSettingsAdvertisementGtagOptions(json)) {
        return FxIdDomainSettingsAdvertisementGtagOptionsFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdDomainSettingsAdvertisementOptionsGtagToJSON(json: any): any {
    return FxIdDomainSettingsAdvertisementOptionsGtagToJSONTyped(json, false);
}

export function FxIdDomainSettingsAdvertisementOptionsGtagToJSONTyped(value?: FxIdDomainSettingsAdvertisementOptionsGtag | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdDomainSettingsAdvertisementGtagOptions(value)) {
        return FxIdDomainSettingsAdvertisementGtagOptionsToJSON(value as FxIdDomainSettingsAdvertisementGtagOptions);
    }

    return {};
}

