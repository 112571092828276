/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum FxIdDomainStoreEnumsMerchant {
    Yookassa = 0,
    Xsolla = 1,
    Odnoklassniki = 2,
    VkontakteCom = 3,
    VkontaktePlay = 4,
    YandexGames = 5,
    AbsoluteGames = 6,
    Kongregate = 7,
    Stripe = 8,
    FacebookGames = 9,
    MoneyMailRu = 10,
    Bank131 = 11,
    WizQ = 12,
    RuStore = 13,
    Telegram = 14,
    GooglePlay = 15,
    AppStore = 16,
    Steam = 17,
    OneStore = 18,
    FacebookInstantGames = 19,
    EpicGamesStore = 20,
    PromoCode = 21,
    YandexGamesBonusMerchant = 22,
    Aghanim = 23,
    Dummy = 100,
    NUMBER_unknown_default_open_api = 11184809
}


export function instanceOfFxIdDomainStoreEnumsMerchant(value: any): boolean {
    for (const key in FxIdDomainStoreEnumsMerchant) {
        if (Object.prototype.hasOwnProperty.call(FxIdDomainStoreEnumsMerchant, key)) {
            if (FxIdDomainStoreEnumsMerchant[key as keyof typeof FxIdDomainStoreEnumsMerchant] === value) {
                return true;
            }
        }
    }
    return false;
}

export function FxIdDomainStoreEnumsMerchantFromJSON(json: any): FxIdDomainStoreEnumsMerchant {
    return FxIdDomainStoreEnumsMerchantFromJSONTyped(json, false);
}

export function FxIdDomainStoreEnumsMerchantFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainStoreEnumsMerchant {
    return json as FxIdDomainStoreEnumsMerchant;
}

export function FxIdDomainStoreEnumsMerchantToJSON(value?: FxIdDomainStoreEnumsMerchant | null): any {
    return value as any;
}

export function FxIdDomainStoreEnumsMerchantToJSONTyped(value: any, ignoreDiscriminator: boolean): FxIdDomainStoreEnumsMerchant {
    return value as FxIdDomainStoreEnumsMerchant;
}

