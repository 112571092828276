/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultAghanimMerchantData } from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultAghanimMerchantData';
import {
    instanceOfFxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultAghanimMerchantData,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultAghanimMerchantDataFromJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultAghanimMerchantDataFromJSONTyped,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultAghanimMerchantDataToJSON,
} from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultAghanimMerchantData';

/**
 * @type FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultAghanim
 * 
 * @export
 */
export type FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultAghanim = FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultAghanimMerchantData;

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultAghanimFromJSON(json: any): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultAghanim {
    return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultAghanimFromJSONTyped(json, false);
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultAghanimFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultAghanim {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultAghanimMerchantData(json)) {
        return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultAghanimMerchantDataFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultAghanimToJSON(json: any): any {
    return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultAghanimToJSONTyped(json, false);
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultAghanimToJSONTyped(value?: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultAghanim | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultAghanimMerchantData(value)) {
        return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultAghanimMerchantDataToJSON(value as FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultAghanimMerchantData);
    }

    return {};
}

