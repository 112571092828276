/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdDomainSettingsAdvertisementGtagForDomainEnvOptions
 */
export interface FxIdDomainSettingsAdvertisementGtagForDomainEnvOptions {
    /**
     * gtm_auth из ссылки
     * @type {string}
     * @memberof FxIdDomainSettingsAdvertisementGtagForDomainEnvOptions
     */
    Auth: string;
    /**
     * gtm_preview из ссылки
     * @type {string}
     * @memberof FxIdDomainSettingsAdvertisementGtagForDomainEnvOptions
     */
    Preview: string;
}

/**
 * Check if a given object implements the FxIdDomainSettingsAdvertisementGtagForDomainEnvOptions interface.
 */
export function instanceOfFxIdDomainSettingsAdvertisementGtagForDomainEnvOptions(value: object): value is FxIdDomainSettingsAdvertisementGtagForDomainEnvOptions {
    if (!('Auth' in value) || value['Auth'] === undefined) return false;
    if (!('Preview' in value) || value['Preview'] === undefined) return false;
    return true;
}

export function FxIdDomainSettingsAdvertisementGtagForDomainEnvOptionsFromJSON(json: any): FxIdDomainSettingsAdvertisementGtagForDomainEnvOptions {
    return FxIdDomainSettingsAdvertisementGtagForDomainEnvOptionsFromJSONTyped(json, false);
}

export function FxIdDomainSettingsAdvertisementGtagForDomainEnvOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsAdvertisementGtagForDomainEnvOptions {
    if (json == null) {
        return json;
    }
    return {
        
        'Auth': json['Auth'],
        'Preview': json['Preview'],
    };
}

export function FxIdDomainSettingsAdvertisementGtagForDomainEnvOptionsToJSON(json: any): FxIdDomainSettingsAdvertisementGtagForDomainEnvOptions {
    return FxIdDomainSettingsAdvertisementGtagForDomainEnvOptionsToJSONTyped(json, false);
}

export function FxIdDomainSettingsAdvertisementGtagForDomainEnvOptionsToJSONTyped(value?: FxIdDomainSettingsAdvertisementGtagForDomainEnvOptions | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Auth': value['Auth'],
        'Preview': value['Preview'],
    };
}

