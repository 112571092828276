/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdDomainSettingsAdvertisementGtagForDomainEnvOptions } from './FxIdDomainSettingsAdvertisementGtagForDomainEnvOptions';
import {
    instanceOfFxIdDomainSettingsAdvertisementGtagForDomainEnvOptions,
    FxIdDomainSettingsAdvertisementGtagForDomainEnvOptionsFromJSON,
    FxIdDomainSettingsAdvertisementGtagForDomainEnvOptionsFromJSONTyped,
    FxIdDomainSettingsAdvertisementGtagForDomainEnvOptionsToJSON,
} from './FxIdDomainSettingsAdvertisementGtagForDomainEnvOptions';

/**
 * @type FxIdDomainSettingsAdvertisementGtagForDomainOptionsEnv
 * Настройки окружения для разработки
 * @export
 */
export type FxIdDomainSettingsAdvertisementGtagForDomainOptionsEnv = FxIdDomainSettingsAdvertisementGtagForDomainEnvOptions;

export function FxIdDomainSettingsAdvertisementGtagForDomainOptionsEnvFromJSON(json: any): FxIdDomainSettingsAdvertisementGtagForDomainOptionsEnv {
    return FxIdDomainSettingsAdvertisementGtagForDomainOptionsEnvFromJSONTyped(json, false);
}

export function FxIdDomainSettingsAdvertisementGtagForDomainOptionsEnvFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsAdvertisementGtagForDomainOptionsEnv {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdDomainSettingsAdvertisementGtagForDomainEnvOptions(json)) {
        return FxIdDomainSettingsAdvertisementGtagForDomainEnvOptionsFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdDomainSettingsAdvertisementGtagForDomainOptionsEnvToJSON(json: any): any {
    return FxIdDomainSettingsAdvertisementGtagForDomainOptionsEnvToJSONTyped(json, false);
}

export function FxIdDomainSettingsAdvertisementGtagForDomainOptionsEnvToJSONTyped(value?: FxIdDomainSettingsAdvertisementGtagForDomainOptionsEnv | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdDomainSettingsAdvertisementGtagForDomainEnvOptions(value)) {
        return FxIdDomainSettingsAdvertisementGtagForDomainEnvOptionsToJSON(value as FxIdDomainSettingsAdvertisementGtagForDomainEnvOptions);
    }

    return {};
}

